import axios from 'axios';
import api from '../config/api';

axios.defaults.headers.common['accessToken'] = localStorage.getItem('accessToken');
axios.defaults.headers.common['Authorization'] = `${api.AUTH}`;

export const loginService = (params) => axios.post(api.ADMIN_LOGIN, params);
export const uploadFileService = (params) => axios.post(api.UPLOAD_FILE, params);
export const getEventsService = (query) => axios.get(api.GET_EVENTS + query);
export const getEventService = (query) => axios.get(api.GET_EVENT + query);
export const createEventService = (params) => axios.post(api.CREATE_EVENT, params);
export const editEventService = (query, params) => axios.put(api.EDIT_EVENT + query, params);
export const getLocationsService = (query) => axios.get(api.GET_LOCATIONS + query);
export const createLocationsService = (params) => axios.post(api.CREATE_LOCATIONS, params);
export const editLocationsService = (query, params) => axios.put(api.EDIT_LOCATIONS + query, params);
export const getDineInService = (query) => axios.get(api.GET_ALL_DINE_IN + query);
export const getDineInServiceById = (query) => axios.get(api.GET_DINE_IN + query);
export const updateDineInService = (query, params) => axios.put(api.GET_DINE_IN + query, params);
export const createDineInService = (params) => axios.get(api.GET_DINE_IN, params);
export const editDineInService = (query, params) => axios.put(api.GET_DINE_IN + query, params);
export const getLocationByIdService = (query) => axios.get(api.GET_LOCATION_BY_ID + query);
