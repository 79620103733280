import { useLocation } from 'react-router-dom';

import helloIcon from '../assets/icons/hello-icon.svg';
import { useEffect, useState } from 'react';


const Header = () => {
    const location = useLocation();
    const [pageName, setPageName] = useState('Admin Panel');


    useEffect(() => {
        if(location.pathname == '/events'){
            setPageName('Events')
        }else if(location.pathname == '/events/create'){
            setPageName('Create Event')
        }else if(location.pathname.includes('/events/update')){
            setPageName('Edit Event')
        }else if(location.pathname == '/locations'){
            setPageName('Locations')
        }else if(location.pathname == '/locations/create'){
            setPageName('Create Location')
        }else if(location.pathname == '/locations/update'){
            setPageName('Edit Location')
        }else if(location.pathname == '/dinein'){
            setPageName('Dine In')
        }else if(location.pathname.includes('/dinein/create')){
            setPageName('Create Dine In')
        }else if(location.pathname.includes('/dinein/update')){
            setPageName('Edit Dine In')
        }else if(location.pathname == '/'){
            setPageName('Events')
        }else{
            setPageName('Admin Panel')
        }


    }, [location.pathname])
    return (
        <header>
            <div className='px-4 py-3'>
                <h4 className='m-0'>Hello, Admin <img src={helloIcon} /></h4>
            </div>

            <div className='admin_bar px-4'>{pageName}</div>
        </header>
    )
}

export default Header;