import { Link } from "react-router-dom";
import { AddIcon, DeleteIcon2, EditIcon, LoadingIcon, SearchIcon } from "../../components/SVG";
import { editDineInService, getDineInService } from "../../service";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const DineInPage = () => {
    const [list, setList] = useState([]);
    const [pageLoader, setPageLoader] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    const [confirmModal, setConfirmModal] = useState(false);
    const [activeId, setActiveId] = useState('');

    const limit = 10;


    const getEvents = () => {
        let query = `?limit=${limit}&page=${page}&search=${search}`;
        setPageLoader(true);
        getDineInService(query).then(res => {
            setPageLoader(false);
            if(res.status == 200){
                setList(res.data.responseData)
            }
        })
    }



    useEffect(() => {
        getEvents()
    }, [search, page])



    const handleClose = () => {
        setConfirmModal(false);
        setActiveId('')
    }



    const handleConfirm = () => {
        let params = {status: 3}
        let query = `?id=${activeId}`
        editDineInService(query, params).then(res => {
            if(res.status == 200){
                handleClose()
            }
        })
    }


    const handleStatusChange = item => {
        let params = {status: item.status == 1 ? 2 : 1}
        let query = `/${item.id}`
        editDineInService(query, params).then(res => {
            if(res.status == 200){
                getEvents()
            }
        })
    }


    return (
        <div className="page_wrapper px-4 py-5">
            <div className="page_top_actions">
                <div>
                    <Link className="btn btn-primary px-4" to="/dinein/create"><AddIcon/> Add Dine In</Link>
                </div>
                <div>
                    <div className="cm_search">
                        <SearchIcon />
                        <input
                            value={search}
                            onChange={e => (setSearch(e.target.value), setPage(1))}
                            placeholder="Search"
                            type="text"
                            className="form-control" />
                    </div>
                </div>
            </div>

            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Manager Name</th>
                            <th>CTA</th>
                            <th></th>
                        </tr>
                    </thead>

                    {
                        pageLoader ? (
                            <tbody>
                                <tr>
                                    <td colSpan={5} className="text-center">
                                        <LoadingIcon />
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            <tbody>
                                {
                                    list.map((item, i) => (
                                        <tr key={i}>
                                            <td><img className='event_img_sm' src={item.img_url} /> <b className="ms-3">{item.name}</b></td>
                                            <td>{item.email}</td>
                                            <td>{item.manager_name}</td>
                                            <td>
                                                <Link to={`/dinein/update/${item.id}`} className="table_action_icon"><EditIcon /></Link>
                                                <span onClick={() => (setActiveId(item.id), setConfirmModal(true))} title="Delete" className="table_action_icon ms-3"><DeleteIcon2 /></span>
                                                <span className="cm_check_box">
                                                    <input checked={item.status == 1} onChange={() => handleStatusChange(item)} type="checkbox"  />
                                                    <span />
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        )
                    }



                    
                    </table>
            </div>


            <Modal show={confirmModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Action</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this Dine In?</Modal.Body>
                <Modal.Footer>
                    <span className="btn btn-secondary px-5" onClick={handleClose}>No</span>
                    <span className="btn btn-primary px-5" onClick={handleConfirm}>Yes</span>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DineInPage;