import { Link } from 'react-router-dom';
import authBg from '../../assets/img/admin-auth-bg.png';
import logoImg from '../../assets/img/logo.svg';
import { useEffect, useState } from 'react';
import { loginService } from '../../service';

const LoginPage = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailErr, setEmailErr] = useState('')
    const [passwordErr, setPasswordErr] = useState('')
    const [btnLoader, setbtnLoader] = useState(false);
    const [resErr, setResErr] = useState('');

    const handleValidate = () => {
        let validate = true;

        if(email == '' || email == 'undefined' || email == null){
            setEmailErr('Email is required');
            validate = false;
        }

        if(password == '' || password == 'undefined' || password == null){
            setPasswordErr('Password is required');
            validate = false;
        }

        return validate

    }


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (handleValidate()) {
            setbtnLoader(true);
            const params = { email, password };
    
            try {
                const res = await loginService(params);
                setbtnLoader(false)
                if(res.status == 200){
                    localStorage.setItem('accessToken', res.data.token);
                    window.location.href = '/';
                }
                setbtnLoader(false);
           
            } catch (error) {
                setResErr('Admin Not Found');
                setbtnLoader(false);
            }
        }
    };

    useEffect(() => {
        document.body.classList.add('is_auth_page');
        return () => {
            document.body.classList.remove('is_auth_page');
        }
    }, [])

    return (
        <div className="auth_wrapper" style={{backgroundImage: `url(${authBg})`}}>
            <div className='auth_box'>
                <div className='d-flex justify-content-center mb-5'>
                    <img className='auth_logo' src={logoImg} />
                </div>
                <h3>Admin Panel LogIn</h3>

                <form onSubmit={handleSubmit}>
                    <div className='form-group'>
                        <input
                            type="email"
                            value={email}
                            onChange={e => (setEmail(e.target.value), setEmailErr(''), setResErr(''))}
                            className={`form-control ${emailErr ? 'is-invalid' : ''}`}
                            placeholder='Enter Your Email*' />

                        {emailErr ? <div className="invalid-feedback">{emailErr}</div> : ''}
                    </div>

                    <div className='form-group'>
                        <input
                            type="password"
                            value={password}
                            onChange={e => (setPassword(e.target.value), setPasswordErr(''), setResErr(''))}
                            className={`form-control ${passwordErr ? 'is-invalid' : ''}`}
                            placeholder='Password*' />

                        {passwordErr ? <div className="invalid-feedback">{passwordErr}</div> : ''}
                    </div>

                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <p className='m-0'>
                            <span className='cm_checkbox_wrpper'>
                                <input id="RememberMe" type='checkbox' />
                                <span />
                            </span>
                            <label htmlFor="RememberMe">Remember me</label></p>
                        <Link className='fp_link' to="/">Forget Password</Link>
                    </div>

                    {resErr ? <div className="cm_err">{resErr}</div> : ''}

                    <div className='form-group'>
                        <button disabled={btnLoader} type='submit' className='btn btn-primary w-100'>Continue</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default LoginPage;