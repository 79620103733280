import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import DashboardPage from "./pages/dashboard/DashboardPage";
import LoginPage from "./pages/login/LoginPage";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import EventsPage from "./pages/events/EventsPage";
import CreateEventPage from "./pages/events/CreateEventPage";
import LocationsPage from "./pages/locations/LocationsPage";
import UpdateEventPage from "./pages/events/UpdateEventPage";
import DineInPage from "./pages/dineIn/DineInPage";
import CreateDineInPage from "./pages/dineIn/CreateDineInPage";
import CreateLocationsPage from "./pages/locations/CreateLocationsPage";
import UpdateLocationPage from "./pages/locations/UpdateLocationPage";
import UpdateDineInPage from "./pages/dineIn/UpdateDineInPage";

function PublicRoute({ element, isAuthenticated, ...rest }) {
  return isAuthenticated ? <Navigate to="/" replace /> : element;
}

function PrivateRoute({ element, isAuthenticated, ...rest }) {
  return isAuthenticated ? element : <Navigate to="/login" replace />;
}

function App() {
  const isAuthenticated = !!localStorage.getItem('accessToken');

  return (
    <Router>
      <div className="App">
        {isAuthenticated && (
          <>
            <Header />
            <Sidebar />
          </>
        )}
       
        <Routes>
          <Route path="/login" element={<PublicRoute isAuthenticated={isAuthenticated} element={<LoginPage />} />} />
          <Route path="/" element={<PrivateRoute isAuthenticated={isAuthenticated} element={<EventsPage />} />} />
          <Route path="/events" element={<PrivateRoute isAuthenticated={isAuthenticated} element={<EventsPage />} />} />
          <Route path="/events/create" element={<PrivateRoute isAuthenticated={isAuthenticated} element={<CreateEventPage />} />} />
          <Route path="/events/update/:id" element={<PrivateRoute isAuthenticated={isAuthenticated} element={<UpdateEventPage />} />} />
          <Route path="/locations" element={<PrivateRoute isAuthenticated={isAuthenticated} element={<LocationsPage />} />} />
          <Route path="/locations/create" element={<PrivateRoute isAuthenticated={isAuthenticated} element={<CreateLocationsPage />} />} />
          <Route path="/locations/update/:id" element={<PrivateRoute isAuthenticated={isAuthenticated} element={<UpdateLocationPage />} />} />
          <Route path="/dinein" element={<PrivateRoute isAuthenticated={isAuthenticated} element={<DineInPage />} />} />
          <Route path="/dinein/create" element={<PrivateRoute isAuthenticated={isAuthenticated} element={<CreateDineInPage />} />} />
          <Route path="/dinein/update/:id" element={<PrivateRoute isAuthenticated={isAuthenticated} element={<UpdateDineInPage />} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
