import { Link } from "react-router-dom";
import parse from 'html-react-parser';
import { AddIcon, DeleteIcon2, EditIcon, FilterIcon, LoadingIcon, SearchIcon } from "../../components/SVG";
import { editLocationsService, getLocationsService } from "../../service";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";


const LocationsPage = () => {
    const [locations, setLocations] = useState([]);
    const [pageLoader, setPageLoader] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    const [confirmModal, setConfirmModal] = useState(false);
    const [activeId, setActiveId] = useState('');

    const limit = 10;


    const getLocations = () => {
        let query = `?limit=${limit}&page=${page}&search=${search}`;
        setPageLoader(true);
        getLocationsService(query).then(res => {
            setPageLoader(false);
            if(res.status == 200){
                setLocations(res.data.responseData)
            }
        })
    }



    useEffect(() => {
        getLocations()
    }, [search, page])


    const handleClose = () => {
        setConfirmModal(false);
        setActiveId('')
    }



    const handleConfirm = () => {
        let params = {status: 3}
        let query = `?id=${activeId}`
        editLocationsService(query, params).then(res => {
            if(res.status == 200){
                handleClose()
            }
        })
    }


    const handleStatusChange = item => {
        let params = {status: item.status == 1 ? 2 : 1}
        let query = `/${item.id}`
        editLocationsService(query, params).then(res => {
            if(res.status == 200){
                getLocations()
            }
        })
    }



    return (
        <div className="page_wrapper px-4 py-5">
            <div className="page_top_actions">
                <div>
                    <Link className="btn btn-primary px-4" to="/locations/create"><AddIcon/> Add Location</Link>
                </div>
                <div>
                    <div className="cm_search">
                        <SearchIcon />
                        <input
                            value={search}
                            onChange={e => (setSearch(e.target.value), setPage(1))}
                            placeholder="Search"
                            type="text"
                            className="form-control" />
                    </div>
                </div>
            </div>

            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Published on</th>
                            {/* <th>Logo</th> */}
                            <th>Image</th>
                            <th>Heading</th>
                            <th>Schedule</th>
                            {/* <th>Note</th> */}
                            <th>Description</th>
                            <th>CTA</th>
                            <th></th>
                        </tr>
                    </thead>

                    {
                        pageLoader ? (
                            <tbody>
                                <tr>
                                    <td colSpan={7} className="text-center">
                                        <LoadingIcon />
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            <tbody>
                        {
                            locations.map((location, i) => (
                                <tr key={i}>
                                    {console.log(location, 'locationlocation')}
                                    <td className='bg_light_pink text-center'>
                                        <b>16/03/2024</b>
                                        <br />
                                        <small>Last Edited on 18/03/2024</small>
                                    </td>
                                    {/* <td><img className='event_img_sm' src={location.logo} /></td> */}
                                    <td><img className='event_img_sm' src={location.image} /></td>
                                    <td>{location.name}</td>

                                    <td><span className="cm_threedots" title={location.schedule}>{parse(location.schedule)}</span></td>
                                   
                                    <td><span className="cm_threedots" title={location.description}>{parse(location.description)}</span></td>
                                
                                    <td>
                                        <Link title="Edit" to={`/locations/update/${location.id}`} className="table_action_icon"><EditIcon /></Link>
                                        <span onClick={() => (setActiveId(location.id), setConfirmModal(true))} title="Delete" className="table_action_icon ms-3"><DeleteIcon2 /></span>
                                        <span className="cm_check_box">
                                            <input checked={location.status == 1} onChange={() => handleStatusChange(location)} type="checkbox"  />
                                            <span />
                                        </span>
                                    </td>

                                    <td></td>
                                </tr>
                            ))
                        }
                    </tbody>
                        )
                    }
                    
                    </table>
            </div>


            <Modal show={confirmModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Action</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this Location?</Modal.Body>
                <Modal.Footer>
                    <span className="btn btn-secondary px-5" onClick={handleClose}>No</span>
                    <span className="btn btn-primary px-5" onClick={handleConfirm}>Yes</span>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default LocationsPage;