import { Link } from "react-router-dom";
import parse from 'html-react-parser';
import { AddIcon, DeleteIcon, DeleteIcon2, EditIcon, FilterIcon, LoadingIcon, SearchIcon } from "../../components/SVG";
import { editEventService, getEventsService } from "../../service";
import { useEffect, useState } from "react";
import { formatDate } from "../../helper";
import { Modal } from "react-bootstrap";

const EventsPage = () => {
    const [events, setEvents] = useState([]);
    const [pageLoader, setPageLoader] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);


    const [confirmModal, setConfirmModal] = useState(false);
    const [activeId, setActiveId] = useState('');



    const limit = 10;


    const getEvents = () => {
        let query = `?limit=${limit}&page=${page}&search=${search}`;
        setPageLoader(true);
        getEventsService(query).then(res => {
            setPageLoader(false);
            if(res.status == 200){
                setEvents(res.data.responseData)
            }
        })
    }



    useEffect(() => {
        getEvents()
    }, [search, page])


    const handleClose = () => {
        setConfirmModal(false);
        setActiveId('')
    }


    const handleConfirm = () => {
        let params = {status: 3}
        let query = `?id=${activeId}`
        editEventService(query, params).then(res => {
            if(res.status == 200){
                handleClose()
            }
        })
    }


    const handleStatusChange = item => {
        let params = {status: item.status == 1 ? 2 : 1}
        let query = `?id=${item.id}`
        editEventService(query, params).then(res => {
            if(res.status == 200){
                getEvents()
            }
        })
    }


    return (
        <div className="page_wrapper px-4 py-5">
            <div className="page_top_actions">
                <div>
                    <Link className="btn btn-primary px-4" to="/events/create"><AddIcon/> Add Event</Link>
                </div>
                <div>
                    <div className="cm_search">
                        <SearchIcon />
                        <input
                            value={search}
                            onChange={e => (setSearch(e.target.value), setPage(1))}
                            placeholder="Search"
                            type="text"
                            className="form-control" />
                    </div>
                </div>
            </div>

            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Published on</th>
                            <th>Logo</th>
                            <th>Image</th>
                            <th>Heading</th>
                            <th>Schedule</th>
                            <th>Note</th>
                            <th>Description</th>
                            <th>CTA</th>
                            <th></th>
                        </tr>
                    </thead>

                    {
                        pageLoader ? (
                            <tbody>
                                <tr>
                                    <td colSpan={9} className="text-center">
                                        <LoadingIcon />
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            <tbody>
                                {
                                    events.map((event, i) => (
                                        <tr key={i}>
                                            <td className='bg_light_pink text-center'>
                                                <b>{event.created_at ? formatDate(event.created_at) : ''}</b>
                                                <br />
                                                <small>Last Edited on {event.updated_at ? formatDate(event.updated_at) : ''}</small>
                                            </td>
                                            <td><img className='event_img_sm' src={event.logo} /></td>
                                            <td><img className='event_img_sm' src={event.image} /></td>
                                            <td>{event.title}</td>

                                            <td><span className="cm_threedots" title={event.schedule}>{parse(event.schedule)}</span></td>
                                            <td><span className="cm_threedots" title={event.note}>{parse(event.note)}</span></td>
                                            <td><span className="cm_threedots" title={event.description}>{parse(event.description)}</span></td>
                                        
                                            <td>
                                                <span className="cm_threedots">
                                                {event?.buttons?.length ? event.buttons.map(button => <a className="btn btn-primary btn-sm px-4" target="_blank" href={button.url}>{button.name}</a>) : ''}
                                                </span>
                                            </td>

                                            <td>
                                                <Link title="Edit" to={`/events/update/${event.id}`} className="table_action_icon"><EditIcon /></Link>
                                                <span onClick={() => (setActiveId(event.id), setConfirmModal(true))} title="Delete" className="table_action_icon ms-3"><DeleteIcon2 /></span>
                                                <span className="cm_check_box">
                                                    <input checked={event.status == 1} onChange={() => handleStatusChange(event)} type="checkbox"  />
                                                    <span />
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        )
                    }
                    
                    </table>
            </div>

            <Modal show={confirmModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Action</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this Event?</Modal.Body>
                <Modal.Footer>
                    <span className="btn btn-secondary px-5" onClick={handleClose}>No</span>
                    <span className="btn btn-primary px-5" onClick={handleConfirm}>Yes</span>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EventsPage;