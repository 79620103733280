import { useState } from "react";
import {createLocationsService, uploadFileService } from "../../service";

const CreateLocationsPage = () => {

    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const [phone_no, setPhone_no] = useState('');
    const [web_url, setWeb_url] = useState('');
    const [address, setAddress] = useState('');
    const [sevenroom_url, setSevenroom_url] = useState('');
    const [schedule, setSchedule] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [insta_url, setInsta_url] = useState('');
    const [fb_url, setFb_url] = useState('');
    const [linkedin_url, setLinkedin_url] = useState('');


    const [nameErr, setNameErr] = useState('');
    const [imageErr, setImageErr] = useState('');
    const [phone_noErr, setPhone_noErr] = useState('');
    const [web_urlErr, setWeb_urlErr] = useState('');
    const [addressErr, setAddressErr] = useState('');
    const [sevenroom_urlErr, setSevenroom_urlErr] = useState('');
    const [scheduleErr, setScheduleErr] = useState('');
    const [latitudeErr, setLatitudeErr] = useState('');
    const [longitudeErr, setLongitudeErr] = useState('');
    const [insta_urlErr, setInsta_urlErr] = useState('');
    const [fb_urlErr, setFb_urlErr] = useState('');
    const [linkedin_urlErr, setLinkedin_urlErr] = useState('');


    const [btnLoader, setBtnLoader] = useState(false);



    const handleFileChange = (e) => {
        setImageErr('');
        let params = new FormData();
        params.append('image', e.target.files[0]);
        uploadFileService(params).then(res => {
            if(res.status == 200){
                setImage(res.data.responseData)
            }
        })

    }




    const handleValidate = () => {
        let validate = true;

        if(name == '' || name == 'undefined' || name == null){
            setNameErr('Name is required');
            validate = false;
        }


        return validate

    }




    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            setBtnLoader(true);
            let params = {
                name,
                image,
                phone_no,
                web_url,
                address,
                sevenroom_url,
                schedule,
                latitude,
                longitude,
                insta_url,
                fb_url,
                linkedin_url,
            }

            createLocationsService(params).then(res => {
                setBtnLoader(false);
                if(res.status == 200){
                    window.location = '/locations'
                }
            })
    
        }
        
    }



    return (
        <div className="page_wrapper px-4 py-5">

            <form onSubmit={handleSubmit}>
                <div className="row">
                        <div className="form-group col-md-4">
                            <label>Name</label>
                            <input
                                className={`form-control ${nameErr ? 'is-invalid' : ''}`}
                                type="text"
                                value={name}
                                onChange={e => (setName(e.target.value), setNameErr(''))} />

                            {nameErr ? <div className="invalid-feedback">{nameErr}</div> : ''}
                        </div>


                        <div className="form-group col-md-4">
                            <label>Phone No</label>
                            <input
                                className={`form-control ${phone_noErr ? 'is-invalid' : ''}`}
                                type="text"
                                value={phone_no}
                                onChange={e => (setPhone_no(e.target.value), setPhone_no(''))} />

                            {phone_noErr ? <div className="invalid-feedback">{phone_noErr}</div> : ''}
                        </div>


                        <div className="form-group col-md-4">
                            <label>Web url</label>
                            <input
                                className={`form-control ${web_urlErr ? 'is-invalid' : ''}`}
                                type="text"
                                value={web_url}
                                onChange={e => (setWeb_url(e.target.value), setWeb_urlErr(''))} />

                            {web_urlErr ? <div className="invalid-feedback">{web_urlErr}</div> : ''}
                        </div>


                        <div className="form-group col-md-4">
                            <label>Address</label>
                            <input
                                className={`form-control ${addressErr ? 'is-invalid' : ''}`}
                                type="text"
                                value={address}
                                onChange={e => (setAddress(e.target.value), setAddressErr(''))} />

                            {addressErr ? <div className="invalid-feedback">{addressErr}</div> : ''}
                        </div>


                        <div className="form-group col-md-4">
                            <label>Sevenroom url</label>
                            <input
                                className={`form-control ${sevenroom_urlErr ? 'is-invalid' : ''}`}
                                type="text"
                                value={sevenroom_url}
                                onChange={e => (setSevenroom_url(e.target.value), setSevenroom_urlErr(''))} />

                            {sevenroom_urlErr ? <div className="invalid-feedback">{sevenroom_urlErr}</div> : ''}
                        </div>


                        <div className="form-group col-md-4">
                            <label>Schedule</label>
                            <input
                                className={`form-control ${scheduleErr ? 'is-invalid' : ''}`}
                                type="text"
                                value={schedule}
                                onChange={e => (setSchedule(e.target.value), setScheduleErr(''))} />

                            {scheduleErr ? <div className="invalid-feedback">{scheduleErr}</div> : ''}
                        </div>


                        <div className="form-group col-md-4">
                            <label>Latitude</label>
                            <input
                                className={`form-control ${latitudeErr ? 'is-invalid' : ''}`}
                                type="text"
                                value={latitude}
                                onChange={e => (setLatitude(e.target.value), setLatitudeErr(''))} />

                            {latitudeErr ? <div className="invalid-feedback">{latitudeErr}</div> : ''}
                        </div>


                        <div className="form-group col-md-4">
                            <label>Longitude</label>
                            <input
                                className={`form-control ${longitudeErr ? 'is-invalid' : ''}`}
                                type="text"
                                value={longitude}
                                onChange={e => (setLongitude(e.target.value), setLongitudeErr(''))} />

                            {longitudeErr ? <div className="invalid-feedback">{longitudeErr}</div> : ''}
                        </div>


                        <div className="form-group col-md-4">
                            <label>Instagram url</label>
                            <input
                                className={`form-control ${insta_urlErr ? 'is-invalid' : ''}`}
                                type="text"
                                value={insta_url}
                                onChange={e => (setInsta_url(e.target.value), setInsta_urlErr(''))} />

                            {insta_urlErr ? <div className="invalid-feedback">{insta_urlErr}</div> : ''}
                        </div>


                        <div className="form-group col-md-4">
                            <label>Facebook url</label>
                            <input
                                className={`form-control ${fb_urlErr ? 'is-invalid' : ''}`}
                                type="text"
                                value={fb_url}
                                onChange={e => (setFb_url(e.target.value), setFb_urlErr(''))} />

                            {fb_urlErr ? <div className="invalid-feedback">{fb_urlErr}</div> : ''}
                        </div>


                        <div className="form-group col-md-4">
                            <label>Linkedin url</label>
                            <input
                                className={`form-control ${linkedin_urlErr ? 'is-invalid' : ''}`}
                                type="text"
                                value={linkedin_url}
                                onChange={e => (setLinkedin_url(e.target.value), setLinkedin_urlErr(''))} />

                            {linkedin_urlErr ? <div className="invalid-feedback">{linkedin_urlErr}</div> : ''}
                        </div>



                        


                        <div className="form-group col-md-6">
                            <label>Image</label>
                            <input
                                className={`form-control ${imageErr ? 'is-invalid' : ''}`}
                                type="file"
                                onChange={handleFileChange} />

                            {imageErr ? <div className="invalid-feedback">{imageErr}</div> : ''}
                            {image ? <img className="event_img" src={image} /> : ''}
                        </div>

                    

                </div>


                <div>
                    <button disabled={btnLoader} className="btn btn-primary px-5" type="submit">Save</button>
                </div>

                
            </form>

        </div>
    )
}

export default CreateLocationsPage;
