import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { DeleteIcon } from "../../components/SVG";
import { createEventService, editEventService, getEventService, uploadFileService } from "../../service";
import { removeHtmlTags, replacePergraphToDiv } from "../../helper";




const UpdateEventPage = () => {
    const location = useLocation();
    const eventId = parseInt(location.pathname.match(/\d+$/)[0])
    const [title, setTitle] = useState('');
    const [logo, setLogo] = useState('');
    const [image, setImage] = useState('');
    const [schedule, setSchedule] = useState('');
    const [note, setNote] = useState('');
    const [description, setDescription] = useState('');
    const [buttons, setButtons] = useState([]);

    const [titleErr, setTitleErr] = useState('');
    const [logoErr, setLogoErr] = useState('');
    const [imageErr, setImageErr] = useState('');
    const [scheduleErr, setScheduleErr] = useState('');
    const [noteErr, setNoteErr] = useState('');
    const [descriptionErr, setDescriptionErr] = useState('');
    const [buttonsErr, setButtonsErr] = useState('');

    const [btnLoader, setBtnLoader] = useState(false);



    useEffect(() => {
        if(eventId){
            let query = `?id=${eventId}`
            getEventService(query).then(res => {
                if(res.status == 200){
                    let _res = res.data.responseData;
                    setTitle(_res.title);
                    setLogo(_res.logo);
                    setImage(_res.image);
                    setSchedule(_res.schedule);
                    setNote(_res.note);
                    setDescription(_res.description);
                    setButtons(_res.buttons);
                }
            })
        }
       

    }, [eventId])



    const handleDeleteBtn = index => {
        let _btns = [...buttons];
        _btns.splice(index, 1);
        setButtons(_btns);

    }


    const handleFileChange = (e, f_type) => {
        let params = new FormData();
        params.append('image', e.target.files[0]);
        

        uploadFileService(params).then(res => {
            if(res.status == 200){
                if(f_type == 'logo'){
                    setLogo(res.data.responseData)
                }
                if(f_type == 'image'){
                    setImage(res.data.responseData)
                }
            }
        })

    }


    const handleButtonChange = (e, i, f_type) => {
        let _btns = [...buttons];
        if(f_type == 'name'){
            _btns[i].name = e.target.value;
            _btns[i].nameErr = '';
        }else{
            _btns[i].url = e.target.value;
            _btns[i].urlErr = '';
        }
        setButtons(_btns);
    }



    const handleValidate = () => {
        let validate = true;

        if(title == '' || title == 'undefined' || title == null){
            setTitleErr('Title is required');
            validate = false;
        }

        if(logo == '' || logo == 'undefined' || logo == null){
            setLogoErr('Logo is required');
            validate = false;
        }

        if(image == '' || image == 'undefined' || image == null){
            setImageErr('Image is required');
            validate = false;
        }

        if(removeHtmlTags(schedule) == ''){
            setScheduleErr('Schedule is required');
            validate = false;
        }

        if(removeHtmlTags(note) == ''){
            setNoteErr('Note is required');
            validate = false;
        }

        if(removeHtmlTags(description) == ''){
            setDescriptionErr('Description is required');
            validate = false;
        }
        if(buttons.length == 0){
            setButtonsErr('Button is required');
            validate = false;
        }

        if(buttons.length > 0){
            let _btns = [...buttons];
            let _res = _btns.map(item => {
                return {
                    ...item,
                    nameErr: item.name == '' ? 'Name is required' : '',
                    urlErr: item.url == '' ? 'Url is required' : ''
                }
            });

            let btnValidate = _res.filter(item => item.nameErr || item.urlErr);

            if(btnValidate.length){
                validate = false;
                setButtons(_res);
            }
        }

        return validate

    }



    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            setBtnLoader(true);
            let params = {
                title,
                logo,
                image,
                schedule: replacePergraphToDiv(schedule),
                note: replacePergraphToDiv(note),
                description,
                buttons
            }

            let query = `?id=${eventId}`

            editEventService(query, params).then(res => {
                setBtnLoader(false);
                if(res.status == 200){
                    window.location = '/events'
                }
            })
    
        }
        
    }



    return (
        <div className="page_wrapper px-4 py-5">

            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-7">
                        <div className="form-group">
                            <label>Title</label>
                            <input
                                className={`form-control ${titleErr ? 'is-invalid' : ''}`}
                                type="text"
                                value={title}
                                onChange={e => (setTitle(e.target.value), setTitleErr(''))} />

                            {titleErr ? <div className="invalid-feedback">{titleErr}</div> : ''}
                        </div>


                        <div className="form-group">
                            <label>Schedule</label>
                            <ReactQuill
                                className={`${scheduleErr ? 'is-invalid' : ''}`}
                                theme="snow"
                                value={schedule}
                                onChange={e => (setSchedule(e), setScheduleErr(''))} />
                            {scheduleErr ? <div className="invalid-feedback">{scheduleErr}</div> : ''}
                        </div>


                        <div className="form-group">
                            <label>Note</label>
                            <ReactQuill
                                className={`${noteErr ? 'is-invalid' : ''}`}
                                theme="snow"
                                value={note}
                                onChange={e => (setNote(e), setNoteErr(''))} />
                            {noteErr ? <div className="invalid-feedback">{noteErr}</div> : ''}
                        </div>

                        <div className="form-group">
                            <label>Description</label>
                            <ReactQuill
                                className={`${descriptionErr ? 'is-invalid' : ''}`}
                                theme="snow"
                                value={description}
                                onChange={e => (setDescription(e), setDescriptionErr(''))} />
                            {descriptionErr ? <div className="invalid-feedback">{descriptionErr}</div> : ''}
                        </div>


                    </div>
                    <div className="col-md-5">

                        <div className="form-group">
                            <label>Logo</label>
                            <input
                                className={`form-control ${logoErr ? 'is-invalid' : ''}`}
                                type="file"
                                onChange={e => handleFileChange(e, 'logo')} />

                            {logoErr ? <div className="invalid-feedback">{logoErr}</div> : ''}
                        </div>

                        {logo ? <img className="event_img" src={logo} /> : ''}

                        <div className="form-group">
                            <label>Image</label>
                            <input
                                className={`form-control ${imageErr ? 'is-invalid' : ''}`}
                                type="file"
                                onChange={e => handleFileChange(e, 'image')} />

                            {imageErr ? <div className="invalid-feedback">{imageErr}</div> : ''}
                        </div>

                        {image ? <img className="event_img" src={image} /> : ''}

                        <div className="form-group">
                            <label>Buttons</label>
                            {
                                buttons.map((item, i) => (
                                    <div className="position-relative d-flex justify-content-between align-items-top gap-2 mb-4 pe-5">
                                        <div className="form-group w-100">
                                            <input
                                                placeholder="Name"
                                                className={`form-control ${item.nameErr ? 'is-invalid' : ''}`}
                                                type="text"
                                                onChange={e => handleButtonChange(e, i, 'name')}
                                                value={item.name} />
                                            {item.nameErr ? <div className="invalid-feedback">{item.nameErr}</div> : ''}
                                        </div>
                                        <div className="form-group w-100">
                                            <input
                                                placeholder="url"
                                                className={`form-control ${item.urlErr ? 'is-invalid' : ''}`}
                                                type="url"
                                                onChange={e => handleButtonChange(e, i, 'url')}
                                                value={item.url} />
                                            {item.urlErr ? <div className="invalid-feedback">{item.urlErr}</div> : ''}
                                        </div>

                                        <div onClick={() => handleDeleteBtn(i)} className="delete_btn"><DeleteIcon /></div>
                                    </div>
                                ))
                            }

                            <div><span onClick={() => (setButtons([...buttons, {name: '', url: ''}]), setButtonsErr(''))} className="btn btn-sm btn-dark px-4">Add Button</span></div>

                            {buttonsErr ? <div className="cm_f_err">{buttonsErr}</div> : ''}
                        </div>

                    </div>
                </div>


                <div>
                    <button disabled={btnLoader} className="btn btn-primary px-5" type="submit">Save</button>
                </div>

                
            </form>

        </div>
    )
}

export default UpdateEventPage;
