export const dev = {
    api: {
        //url: 'https://devapi.revelersclub.com/api/',
        url: 'https://api.revelersclub.com/api/',
        AUTH: 'Basic YWRtaW46YWRtaW4=',
    }
}

export const local = {
    api: {
        url: 'https://devapi.revelersclub.com/api/',
        AUTH: 'Basic YWRtaW46YWRtaW4=',
    }
}

export const prod = {
    api: {
        url: 'https://api.revelersclub.com/api/',
        AUTH: 'Basic YWRtaW46YWRtaW4=',
    }
}