export const removeHtmlTags = e => {
    return e.replace(/<[^>]*>/g, '');
}

export const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}


export const replacePergraphToDiv = (value) => {
    let removeP = value.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>')
    const removeDiv = removeP.replace(/<div>/g, '').replace(/<\/div>/g, '');
    return removeDiv
};
