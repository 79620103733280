import settings from './settings';

export default (() => {
  return {
    'AUTH': settings.api.AUTH,
    'ADMIN_LOGIN': settings.api.url + 'admin/signin',
    'UPLOAD_FILE': settings.api.url + 'location/upload',
    'GET_EVENTS': settings.api.url + 'event/all-events',
    'CREATE_EVENT': settings.api.url + 'event/add-event',
    'GET_EVENT': settings.api.url + 'event/event',
    'EDIT_EVENT': settings.api.url + 'event/edit-event',
    'GET_LOCATIONS': settings.api.url + 'location/all-locations',
    'GET_LOCATION_BY_ID': settings.api.url + 'location',
    'CREATE_LOCATIONS': settings.api.url + 'location/add',
    'EDIT_LOCATIONS': settings.api.url + 'location',
    'GET_DINE_IN': settings.api.url + 'private/dinein',
    'GET_ALL_DINE_IN': settings.api.url + 'private/all-dinein',
  }
})()