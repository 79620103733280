import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { DeleteIcon } from "../../components/SVG";
import { createDineInService, createEventService, uploadFileService } from "../../service";
import { removeHtmlTags, replacePergraphToDiv } from "../../helper";

const CreateDineInPage = () => {

    const [name, setName] = useState('');
    const [skype, setSkype] = useState('');
    const [email, setEmail] = useState('');
    const [manager_name, setManager_name] = useState('');
    const [img_url, setImg_url] = useState('');
    const [location, setLocation] = useState('');

    const [nameErr, setNameErr] = useState('');
    const [skypeErr, setSkypeErr] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [manager_nameErr, setManager_nameErr] = useState('');
    const [img_urlErr, setImg_urlErr] = useState('');
    const [locationErr, setLocationErr] = useState('');

    const [btnLoader, setBtnLoader] = useState(false);



    const handleFileChange = (e) => {
        setImg_urlErr('')
        let params = new FormData();
        params.append('image', e.target.files[0]);
        uploadFileService(params).then(res => {
            if(res.status == 200){
                setImg_url(res.data.responseData)
            }
        })

    }




    const handleValidate = () => {
        let validate = true;

        if(name == '' || name == 'undefined' || name == null){
            setNameErr('Name is required');
            validate = false;
        }

        if(skype == '' || skype == 'undefined' || skype == null){
            setSkypeErr('Skype is required');
            validate = false;
        }

        if(email == '' || email == 'undefined' || email == null){
            setEmailErr('email is required');
            validate = false;
        }

        if(manager_name == '' || manager_name == 'undefined' || manager_name == null){
            setManager_nameErr('Manager name is required');
            validate = false;
        }

        if(img_url == '' || img_url == 'undefined' || img_url == null){
            setImg_urlErr('Image is required');
            validate = false;
        }

        if(location == '' || location == 'undefined' || location == null){
            setLocationErr('Location is required');
            validate = false;
        }

        return validate

    }


    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            setBtnLoader(true);
            let params = {
                name,
                skype,
                email,
                manager_name,
                img_url,
                location,
            }

            createDineInService(params).then(res => {
                setBtnLoader(false);
                if(res.status == 200){
                    window.location = '/dinein'
                }
            })
    
        }
        
    }



    return (
        <div className="page_wrapper px-4 py-5">

            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Name</label>
                            <input
                                className={`form-control ${nameErr ? 'is-invalid' : ''}`}
                                type="text"
                                value={name}
                                onChange={e => (setName(e.target.value), setNameErr(''))} />

                            {nameErr ? <div className="invalid-feedback">{nameErr}</div> : ''}
                        </div>

                        <div className="form-group">
                            <label>Skype</label>
                            <input
                                className={`form-control ${skypeErr ? 'is-invalid' : ''}`}
                                type="text"
                                value={skype}
                                onChange={e => (setSkype(e.target.value), setSkypeErr(''))} />

                            {skypeErr ? <div className="invalid-feedback">{skypeErr}</div> : ''}
                        </div>


                        <div className="form-group">
                            <label>Email</label>
                            <input
                                className={`form-control ${emailErr ? 'is-invalid' : ''}`}
                                type="email"
                                value={email}
                                onChange={e => (setEmail(e.target.value), setEmailErr(''))} />

                            {emailErr ? <div className="invalid-feedback">{emailErr}</div> : ''}
                        </div>



                        <div className="form-group">
                            <label>Manager Name</label>
                            <input
                                className={`form-control ${manager_nameErr ? 'is-invalid' : ''}`}
                                type="text"
                                value={manager_name}
                                onChange={e => (setManager_name(e.target.value), setManager_nameErr(''))} />

                            {manager_nameErr ? <div className="invalid-feedback">{manager_nameErr}</div> : ''}
                        </div>


                        <div className="form-group">
                            <label>Location</label>
                            <input
                                className={`form-control ${locationErr ? 'is-invalid' : ''}`}
                                type="text"
                                value={location}
                                onChange={e => (setLocation(e.target.value), setLocationErr(''))} />

                            {locationErr ? <div className="invalid-feedback">{locationErr}</div> : ''}
                        </div>


                        <div className="form-group">
                            <label>Image</label>
                            <input
                                className={`form-control ${img_urlErr ? 'is-invalid' : ''}`}
                                type="file"
                                onChange={handleFileChange} />

                            {img_urlErr ? <div className="invalid-feedback">{img_urlErr}</div> : ''}
                        </div>

                        {img_url ? <img className="event_img" src={img_url} /> : ''}


                    




                    </div>
                </div>


                <div>
                    <button disabled={btnLoader} className="btn btn-primary px-5" type="submit">Save</button>
                </div>

                
            </form>

        </div>
    )
}

export default CreateDineInPage;
