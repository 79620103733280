export const FilterIcon = () => <svg viewBox="0 0 23 16" fill="none">
<path d="M4.99935 8.0026H18.3327M1.66602 1.33594H21.666M8.33268 14.6693H14.9993" stroke="#344054" strokeWidth="2.22667" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export const AddIcon = () => <svg viewBox="0 0 19 18" fill="none">
<path d="M9.66743 1.22656V16.7821M1.88965 9.00434H17.4452" stroke="white" strokeWidth="2.22667" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export const DeleteIcon = () => <svg viewBox="0 0 20 20" fill="none">
<path d="M10 12.9173L3.95833 18.959C3.57639 19.3409 3.09028 19.5319 2.5 19.5319C1.90972 19.5319 1.42361 19.3409 1.04167 18.959C0.659722 18.577 0.46875 18.0909 0.46875 17.5007C0.46875 16.9104 0.659722 16.4243 1.04167 16.0423L7.08333 10.0007L1.04167 4.01107C0.659722 3.62912 0.46875 3.14301 0.46875 2.55273C0.46875 1.96246 0.659722 1.47635 1.04167 1.0944C1.42361 0.712457 1.90972 0.521484 2.5 0.521484C3.09028 0.521484 3.57639 0.712457 3.95833 1.0944L10 7.13607L15.9896 1.0944C16.3715 0.712457 16.8576 0.521484 17.4479 0.521484C18.0382 0.521484 18.5243 0.712457 18.9063 1.0944C19.3229 1.51107 19.5313 2.00586 19.5313 2.57878C19.5313 3.15169 19.3229 3.62912 18.9063 4.01107L12.8646 10.0007L18.9063 16.0423C19.2882 16.4243 19.4792 16.9104 19.4792 17.5007C19.4792 18.0909 19.2882 18.577 18.9063 18.959C18.4896 19.3757 17.9948 19.584 17.4219 19.584C16.849 19.584 16.3715 19.3757 15.9896 18.959L10 12.9173Z" fill="#4E5051"/>
</svg>

export const SearchIcon = () => <svg viewBox="0 0 23 24" fill="none">
<path d="M21.334 22L16.5007 17.1667M19.1118 10.8889C19.1118 15.7981 15.1321 19.7778 10.2229 19.7778C5.31368 19.7778 1.33398 15.7981 1.33398 10.8889C1.33398 5.97969 5.31368 2 10.2229 2C15.1321 2 19.1118 5.97969 19.1118 10.8889Z" stroke="#667085" strokeWidth="2.22222" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export const EditIcon = () => <svg viewBox="0 0 25 26" fill="none">
<path d="M18.0563 2.9986C18.3481 2.70677 18.6946 2.47528 19.0759 2.31735C19.4572 2.15941 19.8658 2.07813 20.2785 2.07812C20.6912 2.07812 21.0999 2.15941 21.4812 2.31735C21.8625 2.47528 22.2089 2.70677 22.5008 2.9986C22.7926 3.29043 23.0241 3.63687 23.182 4.01816C23.3399 4.39945 23.4212 4.80812 23.4212 5.22082C23.4212 5.63353 23.3399 6.04219 23.182 6.42348C23.0241 6.80477 22.7926 7.15122 22.5008 7.44304L7.50076 22.443L1.38965 24.1097L3.05632 17.9986L18.0563 2.9986Z" stroke="#4E5051" strokeWidth="2.22222" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


export const DeleteIcon2 = () => (
    <svg viewBox="0 0 24 24" width="24px" height="24px">
        <path fill="red" d="M 10 2 L 9 3 L 3 3 L 3 5 L 4.109375 5 L 5.8925781 20.255859 L 5.8925781 20.263672 C 6.023602 21.250335 6.8803207 22 7.875 22 L 16.123047 22 C 17.117726 22 17.974445 21.250322 18.105469 20.263672 L 18.107422 20.255859 L 19.890625 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 6.125 5 L 17.875 5 L 16.123047 20 L 7.875 20 L 6.125 5 z"/>
    </svg>
)

export const DiningIcon = () => <svg class="svg-icon" viewBox="0 0 1024 1024" version="1.1">
    <path d="M514.839273 270.033455l-32.093091 1.559272c-205.870545 9.914182-372.48 181.620364-388.421818 397.498182h814.08c-15.36-208.174545-170.914909-376.459636-367.755637-396.427636l-25.809454-2.629818zM945.338182 738.909091H57.413818C38.539636 738.909091 23.272727 722.874182 23.272727 703.022545 23.272727 433.826909 225.931636 214.062545 479.394909 201.844364V128.977455c0-19.851636 15.266909-35.886545 34.141091-35.886546 18.897455 0 34.164364 16.034909 34.164364 35.886546V203.170909c242.036364 24.552727 431.778909 239.266909 431.778909 499.828364 0 19.851636-15.266909 35.886545-34.141091 35.886545zM58.181818 861.090909h907.636364a34.909091 34.909091 0 0 1 0 69.818182h-907.636364a34.909091 34.909091 0 0 1 0-69.818182z"  />
</svg>


export const LoadingIcon = () => (
    <svg width="24" height="24" stroke="#000" viewBox="0 0 24 24">
      <g style={{transformOrigin: 'center', animation: 'spinner1 2s linear infinite'}}>
        <circle cx="12" cy="12" r="9.5" fill="none" strokeWidth="3" stroke="#00205B" style={{strokeLinecap: 'round', animation: 'spinner2 1.5s ease-in-out infinite'}}></circle>
      </g>
    </svg>
)
