import { NavLink } from 'react-router-dom';
import logoWhiteImg from '../assets/img/logo-white.svg';
import eventIcon from '../assets/icons/event-icon.svg';
import locationIcon from '../assets/icons/location-icon.svg';
import dineinIcon from '../assets/icons/dinein.svg';


const Sidebar = () => {
    return (
        <div className="cm_sidebar">
            <img className='cm_logo' src={logoWhiteImg} />
            <ul>
                {/* <li><NavLink exact to="/" activeClassName="active"><img src={eventIcon} /> Dashboard</NavLink></li> */}
                <li><NavLink to="/events" activeClassName="active"><img src={eventIcon} /> Events</NavLink></li>
                <li><NavLink to="/locations" activeClassName="active"><img src={locationIcon} /> Locations</NavLink></li>
                <li><NavLink to="/dinein" activeClassName="active"><img src={dineinIcon} /> Dine In</NavLink></li>
            </ul>
        </div>
    )
}

export default Sidebar;